<template>
  <div id="app">
      <div class="fixed top-0 h-full w-screen background"/>
        <div class="flex">
            <div class="relative mt-8 ml-3 p-3 bg-white sidebar">
                <calendar-legend/>
            </div>
            <div class="container min-h-screen relative my-8 p-3 bg-white shadow-2xl">
                <calendar-container/>
            </div>
        </div>
  </div>
</template>

<script>
import CalendarContainer from "./components/CalendarContainer.vue"
import CalendarLegend from './components/CalendarLegend.vue'

export default {
    components:{
        CalendarContainer,
        CalendarLegend,
    }
}
</script>

<style>
    .background{
        background-image: url('/images/SU_Campus.jpg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        filter: blur(3px);
    }

    .sidebar{
        height: 40%;
    }
</style>