<template>
	<div id="calendar">
    <h1>Syracuse University Content Calendar</h1>
    <div class="relative">
      <div class="flex justify-center items-center absolute w-full h-full z-10" v-if="loading">
        <div class="spinner"/>
      </div>
      <calendar-view
        :items="items"
        :show-date="showDate"
        class="theme-default holiday-us-traditional holiday-us-official"
        @click-item="onClickItem"
        >
        <template #header="{ headerProps }">
          <calendar-view-header
            :header-props="headerProps"
            @input="setShowDate" />
        </template>
      </calendar-view>
    </div>
	</div>
</template>
<script>
	import { CalendarView, CalendarViewHeader} from "vue-simple-calendar"
  
  import axios from "axios"
  import "vue-simple-calendar/dist/style.css"
  // The next two lines are optional themes
  import "vue-simple-calendar/static/css/default.css"
  import "vue-simple-calendar/static/css/holidays-us.css"

	export default {
		name: 'app',
		data: function() {
			return {
        loading: false,
        showDate: new Date(),
        items: undefined
      }
		},
    async created(){
      this.loading = true;
      let stories = await axios.get('/api/enterprise_stories').then( res =>{
        return res.data
      })
      
      let social = await axios.get('/api/social_posts').then( res =>{
        this.loading = false
        return res.data
      })

      let homePageDates = this.formatData(stories, "DE:homepageFeatureStartDate", ["bg-orange-dark", "text-white"])
      let socialDates = this.formatData(social, "DE:socialPubDate", ["bg-secondary" , "text-white"])
      let socialStoryDates = this.formatData(stories, "DE:socialPubDate", ["bg-secondary" , "text-white"])
      let suTodayDates = this.formatData(stories, "DE:suTodayPubDate", ["bg-tertiary" , "text-white"])
      this.items = [...homePageDates, ...socialDates, ...socialStoryDates, ...suTodayDates]
    },
		components: {
			CalendarView,
			CalendarViewHeader,
		},
		methods: {
			setShowDate(d) {
				this.showDate = d;
			},
      formatData(data, filter, classes){
        let result = data.filter(date => {
          if(date[filter]){
            return date
          }
        })
        result = result.map(x => {
          return this.buildDate(x, filter, classes)
        })
        return result
      },
      buildDate(x, filter, classes){
        return {id: x.ID, startDate: x[filter], endDate: filter === "DE:homepageFeatureStartDate" ? x["DE:homepageFeatureEndDate"]:undefined, title: x["DE:headline"]? x["DE:headline"]:x.name, url: x["DE:publishedURL"], classes: classes }
      },
      onClickItem(e){
        if(e.originalItem.url){
          window.open(e.originalItem.url)
        }
      }
		},
	}
</script>
<style>
  .bg-orange-dark{
    background-color: var(--color-dark-orange) !important;
  }

  .bg-secondary{
    background-color: var(--color-su-blue) !important;
  }

  .bg-tertiary{
    background-color: var(--color-med-gray) !important;
  }
  .text-white{
    color: white
  }

  .bg-gray{
    background-color: #707780 !important;
  }

  .theme-default .cv-item.toBeContinued::after, .theme-default .cv-item.continued::before, .theme-default .cv-item.toBeContinued::after{
    color: black;
  }
  .cv-week{
    min-height: 11em
  }

  .hasUrl:hover{
    opacity: 0.7;
    cursor: pointer
  }
</style>