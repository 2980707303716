<template>
  <div class="p-3 flex flex-col justify-center items-start">
    <div class="flex my-1" v-for="item in items" :key="item.label">
        <button class="flex justify-between items-center p-1 hover:bg-gray-light-50">                
            <div class="box w-3 h-3 mr-4" :class='item.class'/>
            <div>{{item.label}}</div>
        </button>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            items: [
                {
                    label: "Homepage",
                    class: "bg-orange-dark"
                },
                {
                    label: "Social",
                    class: "bg-secondary"
                },
                {
                    label: "SU Today",
                    class: "bg-tertiary"
                }
            ]
        }
    }
}
</script>

<style>
    ul > li::before{
        content: none !important
    }
</style>